.bitacora {
  max-height: 300px;
  overflow-y: auto;
}

.bitacora-item {
  padding: 8px 0;
  border-bottom: 1px solid #eee;
}

.bitacora-item:last-child {
  border-bottom: none;
}

.bitacora-content {
  margin-bottom: 4px;
}

.bitacora-meta {
  color: #6c757d;
  font-size: 12px;
}

.bitacora-input {
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid #eee;
}

.bitacora-input .form-control {
  border: 1px solid #eee;
  border-right: none;
}

.bitacora-input .btn {
  border: 1px solid #eee;
  border-left: none;
  background: white;
} 