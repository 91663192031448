.action-buttons {
  max-width: 400px;
  margin: 2rem auto;
}

.instructions {
  background-color: var(--primary-light);
  border: 1px solid var(--primary-light);
  margin: 2rem 0;
  background-color: #f8f9fa;
}

.instruction-list {
  list-style-type: none;
  padding: 0;
}

.instruction-list li {
  padding: 0.75rem 0;
  font-size: 1.1rem;
  display: flex;
  align-items: center;
}

.step-number {
  background-color: var(--primary);
  color: white;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.75rem;
  font-size: 0.9rem;
  font-weight: bold;
}

.process-divider {
  text-align: center;
  margin: 1rem 0;
  padding: 0.5rem;
  background-color: var(--secondary-light);
  color: var(--secondary);
  font-weight: 600;
  border-radius: 4px;
}

.navigation-buttons {
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
  padding-top: 2rem;
  border-top: 1px solid var(--border-color);
  justify-content: center;
}

.navigation-buttons .btn {
  min-width: 200px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .confirmation-summary {
    padding: 1rem;
  }

  .navigation-buttons {
    flex-direction: column;
  }

  .navigation-buttons .btn {
    width: 100%;
  }
}

.stepper-success-icon {
  background-color: #28a745;
  color: white;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 1rem;
  font-size: 2rem;
  transition: all 0.3s ease;
}

.checklist-card {
  background-color: #e7f2ff;
  border: 1px solid #b6d4fe;
  border-radius: 0.5rem;
  margin: 2rem 0;
  overflow: hidden;
}

.checklist-header {
  background-color: #cfe2ff;
  color: #084298;
  padding: 1rem;
  font-size: 1.1rem;
  font-weight: 600;
}

.checklist-items {
  padding: 1rem;
}

.checklist-item {
  display: flex;
  align-items: center;
  padding: 0.75rem;
  background-color: white;
  border-radius: 0.375rem;
  margin-bottom: 0.5rem;
  box-shadow: 0 1px 2px rgba(0,0,0,0.05);
}

.checklist-item:last-child {
  margin-bottom: 0;
}

.process-divider {
  background-color: #cfe2ff;
  color: #084298;
  text-align: center;
  padding: 0.75rem;
  font-weight: 600;
  margin: 0.5rem 0;
}

.action-buttons {
  max-width: 800px;
  margin: 2rem auto;
}

/* Ajustes responsivos */
@media (max-width: 768px) {
  .action-buttons .row {
    gap: 0.5rem;
  }
  
  .action-buttons .col-md-6 {
    width: 100%;
  }
}

/* Nuevo diseño para la guía de pasos */
.steps-guide {
    background-color: #f0f9ff;
    border: 1px solid #bae6fd;
    padding: 2rem;
    margin: 2rem 0;
}

.steps-guide-title {
  color: var(--bs-gray-700);
  font-size: 1rem;
  margin-bottom: 1.5rem;
  font-weight: 600;
}

.steps-list {
  padding: 0 1rem;
}

.step-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 1.25rem;
  position: relative;
}

.step-marker {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: var(--bs-gray-100);
  color: var(--bs-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
  font-weight: 600;
  flex-shrink: 0;
  border: 2px solid var(--bs-primary);
  margin-right: 1rem;
}

.step-marker.pending {
  background-color: var(--bs-gray-100);
  border-color: var(--bs-gray-400);
  color: var(--bs-gray-600);
}

.step-content {
  font-size: 1rem;
  padding-top: 0.25rem;
  line-height: 1.5;
}

.steps-divider {
  margin: 1.5rem 0;
  padding: 0.5rem;
  text-align: center;
  background-color: #e2e8f0;
  color: #475569;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.5px;
  border-radius: 4px;
}

/* Línea conectora entre pasos */
.step-item:not(:last-child)::after {
  content: '';
  position: absolute;
  left: 14px;
  top: 28px;
  bottom: -14px;
  width: 2px;
  background-color: var(--bs-gray-200);
}

.terms-section {
  background-color: #f0f9ff;
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 2rem;
}

.terms-item {
  display: flex;
  gap: 1rem;
  padding: 0.75rem 0;
  border-bottom: 1px solid #bae6fd;
}

.terms-item:last-child {
  border-bottom: none;
}

.terms-icon {
  font-size: 1.2rem;
  min-width: 24px;
}

/* Ajustes responsivos */
@media (max-width: 768px) {
  .steps-guide {
    padding: 0;
  }
  
  .steps-list {
    padding: 0 0.5rem;
  }
} 

.summary-section {
  background-color: #f8fafc;
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
}

.summary-section h2 {
  color: var(--text-color);
  font-size: 1.4rem;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.summary-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 0;
  border-bottom: 1px solid #e5e7eb;
}

.summary-item:last-child {
  border-bottom: none;
}

.summary-item.highlighted {
  background-color: #fff7ed;
  margin: 0.5rem -1.5rem;
  padding: 1rem 1.5rem;
  border-left: 4px solid var(--warning-color);
}

.summary-label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #64748b;
}

.summary-value {
  font-weight: 600;
  color: var(--text-color);
}

.summary-value.large {
  font-size: 1.2rem;
  color: var(--primary-color);
}