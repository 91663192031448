/* Estilos generales para carga de documentos */
.document-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.document-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px dashed #007bff;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 15px;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
}

.document-item.uploaded {
  border-color: #28a745;
  background-color: #e6ffe6;
}

.document-item.pending {
  border-color: #ffc107;
  background-color: #fff8e1;
}

.document-item:hover {
  background-color: #f1f1f1;
}

.document-info {
  display: flex;
  align-items: center;
}

.document-name {
  margin-left: 10px;
  font-size: 1.1rem;
  font-weight: 500;
}

.document-status {
  font-size: 1.2rem;
}

.document-actions {
  font-size: 1.2rem;
}

.status-icon.success {
  color: #28a745;
}

.status-icon.pending {
  color: #ffc107;
}

/* Modal Estilos */
.document-upload-modal .modal-content {
  background: #fff;
  border-radius: 8px;
}

.document-upload-modal .modal-body {
  padding: 20px;
}

.document-upload-container {
  max-width: 800px;
  margin: 0 auto;
}

/* QR Styles */
.qr-container img {
  width: 120px;
  height: 120px;
}

/* Responsive */
@media (max-width: 768px) {
  .document-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .document-status {
    margin-top: 10px;
  }
  .document-actions {
    margin-top: 10px;
  }
} 

/* Mobile */

.mob-document-upload-container {
  margin-bottom: 1rem;
}

.mob-document-pending {
  border: 2px dashed #ffc107;
  border-radius: 8px;
  padding: 1rem;
  background-color: #fff8e1;
}

.mob-document-title {
  font-size: 0.9rem;
  margin-bottom: 1rem;
}

.mob-document-actions {
  display: flex;
  gap: 0.75rem;
}

.mob-document-actions .btn {
  flex: 1;
  padding: 0.75rem;
  font-size: 1rem;
}

.mob-document-uploaded {
  padding: 0.75rem;
  border-radius: 8px;
  background-color: #d4edda;
  color: #155724;
  font-size: 0.9rem;
}

.documents-grid {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

@media (max-width: 580px) {
  .mob-document-actions {
    flex-direction: column;
  }
  
  .mob-document-actions .btn {
    width: 100%;
    padding: 1rem;
  }
}