.homonym-card {
    transition: transform 0.2s, box-shadow 0.2s;
    border: 1px solid #ddd;
  }
  
  .homonym-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
.main-action {
    transition: all 0.3s ease;
    border: 1px var(--bs-card-bg) solid;
  }

  .main-action:hover {
    box-shadow: 0 8px 24px rgba(0, 102, 255, 0.15);
    transform: translateY(-3px);
  }

  div#purchase:hover, div#purchase.selected {
    border: 1px #198754 solid;
  }

  div#payment:hover, div#payment.selected {
    border: 1px #6c757d solid;
  }

  .loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1050;
  }
  
  .progress-container {
    width: 150px;
    margin: 0 auto;
    position: relative;
  }

  .main-action-button {
    border-width: 2px !important;
    margin-bottom: 1rem !important;
  }
  
  .main-action-button:not(:disabled):hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;
  }
  
  .main-action-button:not(:disabled):hover i {
    color: #fff !important;
  }
  
  .main-action-button.disabled {
    opacity: 0.6;
    background-color: #f8f9fa;
    border-color: #dee2e6;
    color: #6c757d;
  }
  
  .main-action-button:disabled i {
    color: #6c757d !important;
  }
  
  .main-action-button i {
    color: #198754;
    transition: color 0.3s ease;
  }
  
  .btn-success .main-action-button i {
    color: #fff !important;
  }