.layout-horizontal .header-top .logo img {
    height: 50px;
}

span.input-group-text i.bi {
    height: auto !important;
}

.layout-wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.content-wrapper {
  flex: 1 0 auto;
}

footer {
  flex-shrink: 0;
}