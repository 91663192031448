.skeleton-wrapper {
  width: 100%;
}

.skeleton-row {
  display: flex;
  padding: 1rem 0;
  border-bottom: 1px solid #eee;
  animation: pulse 1.5s infinite;
}

.skeleton-cell {
  height: 20px;
  background-color: #eee;
  margin-right: 1rem;
  border-radius: 4px;
}

@keyframes pulse {
  0% {
    opacity: .6;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: .6;
  }
} 