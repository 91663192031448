.action-icon {
  font-size: 1.2rem;
  cursor: pointer;
  padding: 0.5rem;
  transition: transform 0.2s;
}

.action-icon:hover {
  transform: scale(1.2);
}

.action-icon.text-primary:hover {
  color: #0056b3 !important;
}

.action-icon.text-danger:hover {
  color: #dc3545 !important;
}

.action-icon.text-info:hover {
  color: #0dcaf0 !important;
}

.action-icon.text-success:hover {
  color: #198754 !important;
} 