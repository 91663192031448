/* src/components/PurchaseFlow/Step/DocumentUploadStep.css */

/* Lista de Documentos */
#documentUploadStep .document-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  #documentUploadStep .document-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 2px dashed #007bff;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 15px;
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s;
  }
  
  #documentUploadStep .document-item.uploaded, #documentUploadStep .document-item.selected {
    border-color: #28a745;
    background-color: #e6ffe6;
  }
  
  #documentUploadStep .document-item.pending {
    border-color: #ffc107;
    background-color: #fff8e1;
  }
  
  #documentUploadStep .document-item:hover {
    background-color: #f1f1f1;
  }
  
  #documentUploadStep .document-info {
    display: flex;
    align-items: center;
  }
  
  #documentUploadStep .document-name {
    margin-left: 10px;
    font-size: 1.1rem;
    font-weight: 500;
  }
  
  #documentUploadStep .document-status {
    font-size: 1.2rem;
  }
  
  #documentUploadStep .status-icon.success {
    color: #28a745;
  }
  
  #documentUploadStep .status-icon.pending {
    color: #ffc107;
  }
  
  /* Modal Estilos */
  #documentUploadStep .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  #documentUploadStep .modal-content {
    background: #fff;
    border-radius: 8px;
    width: 90%;
    max-width: 500px;
    padding: 20px;
    position: relative;
  }
  
  #documentUploadStep .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  #documentUploadStep .close-button {
    background: transparent;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  #documentUploadStep .modal-body {
    margin-top: 10px;
  }
  
  /* QR Styles */
  #documentUploadStep .qr-container img {
    width: 120px;
    height: 120px;
  }
  
  /* Responsive */
  @media (max-width: 768px) {
    #documentUploadStep .document-item {
      flex-direction: column;
      align-items: flex-start;
    }
  
    #documentUploadStep .document-status {
      margin-top: 10px;
    }
  }

.document-viewer {
  background-color: #f8f9fa;
  min-height: 300px;
  max-height: 80vh;
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.document-viewer .react-pdf__Document {
  max-width: 100%;
  padding: 1rem;
}

.document-viewer .react-pdf__Page {
  max-width: 100%;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  background-color: white;
}

.document-viewer-modal, .document-preview-modal {
  z-index: 1060 !important;
}

.document-viewer-modal-backdrop, .document-preview-modal-backdrop {
  z-index: 1059 !important;
}
